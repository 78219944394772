import { useEffect } from "react";
import { Analytics }  from '@vercel/analytics/react';
import Head from "next/head";

import 'bootstrap/dist/css/bootstrap.css';
import '@/styles/globals.css';

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

let seoKeywords = "monteiro, MonteiroTI, ti, consultoria";

export default function App({ Component, pageProps }) {
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content={seoKeywords} />
      </Head>
      <Component {...pageProps} />
      <Analytics />
    </>
  );
}